<template>
  <div class="justify-content-center align-items-center">
    <div class="text-center mt-4 px-4">
      <img
        class="mb-2"
        src="../assets/images/OwnWords-Delete.png"
        width="136"
      />
      <h1>{{ popupContent.title }}</h1>
      <p>{{ popupContent.description }}</p>
      <div class="row justify-content-center align-items-center mt-5">
        <div class="col-12">
          <k-button
            class="btn btn-secondary m-auto mb-2 w-100"
            @click="$emit('closeWindow')"
            >{{ this.closeBttText }}</k-button
          >
        </div>
        <div class="col-12" v-if="!this.popupContent.disableConfirm">
          <k-button
            class="btn btn-outline-primary m-auto w-100"
            @click="$emit('confirm')"
            >{{ this.deleteBtnText }}</k-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupContentComponent",
  props: { popupContent: Object },

  computed: {
    closeBttText: function () {
      return this.popupContent.closeBttText
        ? this.popupContent.closeBttText
        : "No, take me back";
    },
    deleteBtnText: function () {
      return this.popupContent.deleteBtnText
        ? this.popupContent.deleteBtnText
        : "Yes, delete";
    },
  },
};
</script>
