<template>
  <footer class="footer text-center">
    <div class="copyright">
      (c) {{new Date().getFullYear()}} Own Words. All rights reserved |
      <a href="http://axiosdev.com.au" class="text-white text-decoration-none">
        Powered by Axios
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  name: "login",
  computed: {},
};
</script>
