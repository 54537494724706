<template>
  <div
    ref="mediaPlayer"
    v-bind:style="
      this.media.customSize
        ? { height: this.videoHeight + 'px' }
        : this.media.initialFullScreen
        ? { height: 90 + 'vh' }
        : {}
    "
  >
    <div class="row align-items-center justify-content-center">
      <div
        class="loader_box col-12"
        v-bind:style="
          this.media.customSize
            ? { width: 100 + '%', height: this.videoHeight + 'px' }
            : this.media.initialFullScreen
            ? {
                width: this.videoWidth + 'px',
                height: this.videoHeight + 'px',
              }
            : {}
        "
      >
        <div
          v-if="(this.videoLoading || !this.isVideoType) && !this.errorCode"
          class="loader_overlays"
          v-bind:style="{
            width: this.overlaysWidth + 'px',
            height: this.videoHeight + 'px',
          }"
        >
          <loading
            :active="(this.videoLoading || !this.isVideoType) && !this.errorCode"
            :can-cancel="false"
            :on-cancel="true"
            :is-full-page="false"
            :color="'#e12164'"
            :background-color="'#ababab'"
            :loader="'dots'"
            :width="50"
            :height="30"
          />

          <div class="loader_processing">Video Processing...</div>
        </div>

        <div>
          <video
            v-bind:style="
              this.media.customSize
                ? {
                    width: 100 + '%',
                    height: this.videoHeight + 'px',
                    backgroundColor: 'black !important',
                  }
                : this.media.initialFullScreen
                ? {
                    width: this.videoWidth + 'px',
                    height: this.videoHeight + 'px',
                  }
                : {}
            "
            playsinline
            controls
            :autoplay="this.media.autoplay"
            v-if="this.isVideoType"
            :src="this.videoSource"
            ref="video"
            class="bg-dark controls"
            preload="metadata"
            :onerror="onErrorLoading"
            :oncanplay="onCanPlay"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OsService from "../services/os-service";
import Loading from "vue-loading-overlay";
export default {
  components: {
    Loading,
  },
  props: { media: Object },
  mounted() {
    this.$refs.mediaPlayer
      ? (this.videoHeight = (9 * this.$refs.mediaPlayer.offsetWidth) / 16.0)
      : "";
    this.loadedMetaData();
  },
  computed: {
    videoSource() {
      return OsService.getOS() === "Android" || OsService.getOS() === "Windows"
        ? this.media.videoUri
        : `${this.media.videoUri}#t=0.1`;
    },
    isVideoType() {
      return (
        !this.media.isLoading &&
        this.media.mimeType &&
        this.media.mimeType.startsWith("video")
      );
    },
  },
  created() {
    window.addEventListener("resize", this.resizeBrowserHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeBrowserHandler);
  },
  data() {
    return {
      ratio: 16 / 9.0,
      videoHeight: 318,
      videoWidth: 565,
      overlaysWidth: 565,
      videoLoading: false,
      errorCode: null
    };
  },
  methods: {
    loadedMetaData() {
      let ratioTimes = this.media.initialFullScreen ? 0.7 : 1;
      if (innerWidth < 650 || innerHeight < 650) {
        ratioTimes = 0.65;
      }
      if (this.$refs.mediaPlayer) {
        this.videoHeight = this.$refs.mediaPlayer.offsetHeight
          ? (9 * this.$refs.mediaPlayer.offsetWidth * ratioTimes) / 16.0
          : this.videoHeight;
        if ((innerWidth < 650 || innerHeight < 650) && !this.media.inPopup) {
          this.videoHeight += 50;
        }
        this.videoWidth = this.$refs.mediaPlayer.offsetWidth * ratioTimes;
        this.overlaysWidth = this.$refs.mediaPlayer.offsetWidth;
      }
    },
    resizeBrowserHandler() {
      this.loadedMetaData();
    },
    onErrorLoading() {
      if (this.media && !this.media.isLoading && this.$refs.video) {
        this.errorCode = this.$refs.video.error.code;
        this.videoLoading = true;
        setTimeout(() => this.$refs.video.load(), 5000);
      }
    },
    onCanPlay() {
      this.videoLoading = false;
    },
  },
};
</script>
