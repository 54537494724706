import AxiosStatic from "axios";

class SkillService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list() {
    return this.$http.get("/api/Skill/List");
  }
}

export default new SkillService();
