export  function formatErrorsAndRethrow(error) {
    if (error.response) {
      let err = error.response.data;
      if (err.errors) {
        err = [
          ...Object.keys(error.response.data.errors).map(
            (key) => error.response.data.errors[key]
          ),
        ];
        err = err.reduce((flat, list) => flat.concat(list));
        // console.log(err);
        err = err.reduce((val, arr) => `${val} \r\n${arr}`, "");
      }
      throw new Error(err);
    } else {
      throw new Error("An unexpected error has ocurred");
    }
  }