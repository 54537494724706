<template>
  <div>
    <img
      v-if="useDefaultLogo || !computedFileID || genericLogin"
      class="mb-2 cursor-pointer img-fluid"
      src="../assets/images/OwnWords-Icon.png"
      width="92"
      height="92"
      @click="onClickLogo"
    />
    <img
      v-else
      class="mb-2 cursor-pointer img-fluid"
      :src="`/api/File/stream/${computedFileID}`"
      width="92"
      height="92"
      @click="onClickLogo"
    />
  </div>
</template>


<script>
import EmployerService from "../services/employer-service"
import store from "../store"

export default {
  props: ['fileId', 'employerUrlName', 'useDefaultLogo', 'notClickable'],
  data() {
    return {
      employer: null
    }
  },
  methods: {
    onClickLogo() {
      if(this.notClickable) return;

      if(this.computedEmployerUrlName) {
        this.$router.push(`/${this.computedEmployerUrlName}/job-board`);
      } else {
        this.$router.push("/");
      }
    },
  },
  async beforeCreate() {
    this.employer = await EmployerService.ensureEmployerFromUrlLoaded(this.$route, store);
  },
  computed: {
    genericLogin() {
      return this.$route.name === "LogIn";
    },
    computedEmployerUrlName() {
      if(this.employerUrlName) return this.employerUrlName;

      if(this.employer == null) return null;
      
      return this.employer.urlName;
    },
    computedFileID() {
      if(this.fileId) return this.fileId;

      if(this.employer == null) return null;
      
      return this.employer.logoFileID;
    }
  }
};
</script>
