<template>
    <section id="popup-login-section" v-if="windowVisible">
        <k-dialog
        id="popup-login-option"
        :height="520"
        :width="400"
        v-if="windowLogInOptionVisible"
        :title="'&nbsp;'"
        @close="closeWindow"
        >
        <div class="row h-100 align-items-center">
            <div class="col-12 text-center pb-5">
            <Logo></Logo>
            <h1>Candidate Log In</h1>
            <div>
                <button
                class="btn btn-lg btn-secondary w-100 mb-2"
                @click="ShowLogInPopup"
                >
                Log In to my account
                </button>
                <button
                class="btn btn-lg btn-primary w-100"
                @click="ShowSignUpPage"
                >
                No account? Create one now
                </button>
            </div>
            </div>
        </div>
        </k-dialog>

        <k-dialog
        id="popup-login-fields"
        v-if="!windowLogInOptionVisible"
        :title="'&nbsp;'"
        @close="closeWindow"
        :width="400"
        >
        <ax-logInComponent
            :popup="true"
            @closePopup="closeWindowAfterLoggedIn"
        ></ax-logInComponent>
        </k-dialog>
    </section>
</template>

<script>
import {getUrlName} from "../router";
import LogInComponent from "../components/LogInComponent.vue";

export default {
  components: {
    "ax-logInComponent": LogInComponent,
  },
  props: [],
  data() {
    return {
        windowLogInOptionVisible: true,
        windowVisible: false,
        data: null
    }
  },
  computed: {},
  methods: {
    ShowLogInPopup() {
      this.windowLogInOptionVisible = false;
    },
    async ShowSignUpPage() {
      const urlName = await getUrlName(this.$route);
      if(urlName) {
        this.$router.push(`/${urlName}/sign-up`);
      } else {
        this.$router.push("/sign-up");
      }
    },
    closeWindow() {
        this.windowLogInOptionVisible = true;
        this.windowVisible = false;
    },
    closeWindowAfterLoggedIn() {
        this.closeWindow();
        this.$emit('loggedIn', this.data);
    },
    showWindow(data) {
        this.data = data;
        this.windowVisible = true;
    }
  }
};
</script>