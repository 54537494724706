<template>
  <div
    ref="mediaPlayer"
    class="row align-items-center justify-content-center"
    v-bind:style="
      this.media.initialFullScreen ? {} : { height: videoHeight + 'px' }
    "
  >
    <div
      class="loader_box col-12 p-0"
      :style="
        this.media.initialFullScreen
          ? { width: this.videoWidth + 'px', height: this.videoHeight + 'px' } //
          : { height: videoHeight + 'px', width: 100 + '%' } //
      "
    >
      <div
        v-if="this.media.isLoading"
        class="loader_overlays"
        v-bind:style="{
          width: this.videoWidth + 'px',
          height: this.videoHeight + 'px',
        }"
      >
        <loading
          :active="this.media.isLoading"
          :can-cancel="false"
          :on-cancel="true"
          :is-full-page="false"
          :color="'#e12164'"
          :background-color="'#ababab'"
          :loader="'dots'"
          :width="50"
          :height="30"
        />

        <div class="loader_processing">Video Processing...</div>
      </div>
      <div>
        <video
          :style="
            this.media.initialFullScreen
              ? {
                  width: this.videoWidth + 'px',
                  height: this.videoHeight + 'px',
                } //
              : {
                  height: videoHeight + 'px',
                  width: 100 + '%',
                  backgroundColor: 'black !important',
                }
          "
          @ended="this.nextVideo()"
          ref="video"
          id="video"
          :src="source"
          :key="key"
          :autoplay="this.media.autoplay"
          playsinline
          controls
          class="bg-dark controls"
          preload="metadata"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import OsService from "../services/os-service";
export default {
  components: { Loading },
  props: { media: Object },
  async mounted() {
    if (this.media.listVideoID.length != 0) {
      this.source =
        OsService.getOS() === "Android" || OsService.getOS() === "Windows"
          ? `/api/File/VideoStream/${this.media.listVideoID[this.videoIndex]}`
          : `/api/File/VideoStream/${this.media.listVideoID[this.videoIndex]}#t=0.1`;
    }
    document.getElementById("video").load();
    let ratioTimes = this.media.initialFullScreen ? 0.7 : 1;
    if (innerWidth < 650 || innerHeight < 650) ratioTimes = 0.65;
    this.videoHeight =
      (9 * this.$refs.mediaPlayer.offsetWidth * ratioTimes) / 16.0;
    this.videoWidth = this.$refs.mediaPlayer.offsetWidth * ratioTimes;
    this.$emit("calVideoHeight", {
      height: this.videoHeight,
      width: this.videoWidth,
    });
    this.emitter.on("playMediaPlayerWithSources", () => {
      document.getElementById('video').play();
    });
    this.emitter.on("newSources", ({videoFileIDList, sectionIndex}) => {
      this.listVideoID = videoFileIDList;
      var video = document.getElementById('video');
      video.src = `/api/File/VideoStream/${this.listVideoID[sectionIndex ?? 0]}`;
    });

    this.loadedMetaData();
  },
  data() {
    return {
      videoIndex: 0,
      ratio: 16 / 9.0,
      source: `/api/File/VideoStream/${this.media.listVideoID[this.videoIndex]}`,
      videoHeight: 0,
      videoWidth: 0,
      key: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeBrowserHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeBrowserHandler);
  },
  methods: {
    nextVideo() {
      if (this.videoIndex != this.media.listVideoID.length - 1) {
        let video = document.getElementById("video");
        this.videoIndex += 1;
        this.source = `/api/File/VideoStream/${this.media.listVideoID[this.videoIndex]}`;
        video.src = this.source;
        video.load();
        video.autoplay = true;
        const playPromise = video.play();
        if (playPromise !== undefined) {
          // catch and handle run time error
          playPromise
            .then(() => {})
            .catch(() => {});
        }
      } else {
        // replay entire video
        this.videoIndex = 0;
        this.source = `/api/File/VideoStream/${this.media.listVideoID[this.videoIndex]}`;
        this.key++;
        this.emitter.emit("videoEnd", true);
      }
    },
    loadedMetaData() {
      let ratioTimes = this.media.initialFullScreen ? 0.7 : 1;
      if (innerWidth < 650 || innerHeight < 650) ratioTimes = 0.65;
      if (this.$refs.mediaPlayer) {
        this.videoHeight = this.$refs.mediaPlayer.offsetHeight
          ? (9 * this.$refs.mediaPlayer.offsetWidth * ratioTimes) / 16.0
          : this.videoHeight;
        if ((innerWidth < 650 || innerHeight < 650) && !this.media.inPopup) {
          this.videoHeight += 50;
        }
        this.videoWidth = this.$refs.mediaPlayer.offsetWidth * ratioTimes;
        this.$emit("calVideoHeight", {
          height: this.videoHeight,
          width: this.videoWidth,
        });
      }
    },
    resizeBrowserHandler() {
      this.loadedMetaData();
    },
  },
};
</script>
