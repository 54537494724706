import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import "bootstrap/dist/js/bootstrap.min.js";
import authService from "./services/auth-service";
import authHeader from "./services/auth-header";

//Kendo
import { Grid } from "@progress/kendo-vue-grid";
import { Pager } from "@progress/kendo-vue-data-tools";
import { DropDownList, AutoComplete } from "@progress/kendo-vue-dropdowns";
import { Window, Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Field, Form } from "@progress/kendo-vue-form";
import { Error, Hint, Label } from "@progress/kendo-vue-labels";
import {
  Input,
  MaskedTextBox,
  NumericTextBox,
  Checkbox,
} from "@progress/kendo-vue-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-vue-dateinputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import { Tooltip } from '@progress/kendo-vue-tooltip';
import { PanelBar } from "@progress/kendo-layout-vue-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImage,
  CardActions,
} from "@progress/kendo-vue-layout";
import { Upload } from "@progress/kendo-vue-upload";
import { Expand } from "@progress/kendo-vue-animation";
import { Switch } from "@progress/kendo-inputs-vue-wrapper";
import { Skeleton } from "@progress/kendo-vue-indicators";
import { BadgeContainer, Badge } from "@progress/kendo-vue-indicators";
import { SvgIcon } from "@progress/kendo-vue-common";
import VueTimepicker from 'vue3-timepicker'

//text editor
import { VueEditor } from "vue3-editor";
//Multi select
import Multiselect from "@vueform/multiselect";
//custom no results found
import NoResultsFound from "@/components/NoResultsFound.vue";

//Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

// eslint-disable-next-line no-unused-vars
import { DatePrototype } from "./prototypes/prototype-date";

import BaseLayout from "@/layouts/BaseLayout.vue";

//Media player

//Axios Components
import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
import Loader from "@/components/Loader.vue";
import MediaPlayer from "@/components/MediaPlayer.vue";
import MediaPlayerWithSources from "@/components/MediaPlayerWithSources.vue";
import AudioRecorder from "@/components/AudioRecorder.vue";
import Version from "@/components/Version.vue";
import Footer from "@/components/Footer.vue";
import Logo from "@/components/OwnWordsLogo.vue";
import Pagination from "v-pagination-3";
import PopupTemplate from "@/components/PopupTemplate.vue";
import LoginPopup from "@/components/LoginPopup.vue";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

//Notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'vue3-timepicker/dist/VueTimepicker.css';
const options = {
  // default options here
  position: "bottom-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  maxToasts: 1,
};

//Emit
import mitt from "mitt";
const emitter = mitt();

//Clean console when build production
// import { CleanConsole } from "@eaboy/clean-console";
// CleanConsole.init();

const app = createApp(App).use(store).use(router);

axios.interceptors.request.use((config) => {
  let token = authHeader(store);

  if (token !== localStorage.getItem("jwt_token")) {
    store.dispatch("initialise");
    token = authHeader(store);
  }

  if (token.Authorization && config.headers) {
    config.headers.Authorization = token.Authorization;
  }

  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
});
axios.interceptors.response.use(
  (resp) => resp, //plain passthrough for successful responses
  async (err) => {
    if (err.response) {
      let url = err.request.responseURL || err.request.baseURL;

      const isRefreshError = url.toLowerCase().indexOf("/api/account/refreshtoken") > 0; // Error occured in refresh command
      const isLogOutError = url.toLowerCase().indexOf("/api/account/logoff") > 0; // Error occured in logout command

      if (err.response.status === 401 && !err.config._retry && !isLogOutError) {
        err.config._retry = true;
        try {
          if (isRefreshError) {
            authService.logout().then(() => {
              router.push("/");
            });
            return Promise.reject(new Error("Could not refresh login token"));
          }

          const rs = await authService.refreshToken();
          if (rs && rs.data && rs.data.jwt && rs.data.jwt.authenticated) {
            return axios(err.config);
          }
        } catch (_err) {
          if (_err.response && _err.response.data) {
            return Promise.reject(err.response.data);
          }
        }
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

app.config.globalProperties.$axios = axios;

app.config.performance = true;

// Global error handler
app.config.errorHandler = function (err) {
  console.error(err);
};

app.config.errorCaptured = function (err) {
  console.error(err);
}

//Use notification
app.use(Toast, options);

//Emit
app.config.globalProperties.emitter = emitter;

//Kendo component registration
app.component("k-dropdownlist", DropDownList);
app.component("k-grid", Grid);
app.component("k-window", Window);
app.component("k-form", Form);
app.component("k-button", Button);
app.component("k-button-group", ButtonGroup);
app.component("k-field", Field);
app.component("k-error", Error);
app.component("k-hint", Hint);
app.component("k-label", Label);
app.component("k-input", Input);
app.component("k-maskedTextBox", MaskedTextBox);
app.component("k-numericTextBox", NumericTextBox);
app.component("k-checkbox", Checkbox);
app.component("k-datePicker", DatePicker);
app.component("k-timePicker", TimePicker);
app.component("k-notification", Notification);
app.component("k-tooltip", Tooltip);
app.component("k-dialog", Dialog);
app.component("k-dialog-action", DialogActionsBar);
app.component("k-panelbar", PanelBar);
app.component("k-card", Card);
app.component("k-cardHeader", CardHeader);
app.component("k-cardBody", CardBody);
app.component("k-cardTitle", CardTitle);
app.component("k-cardSubtitle", CardSubtitle);
app.component("k-cardImage", CardImage);
app.component("k-cardActions", CardActions);
app.component("k-upload", Upload);
app.component("k-expand", Expand);
app.component("k-switch", Switch);
app.component("k-skeleton", Skeleton);
app.component("k-pager", Pager);
app.component("k-badgeContainer", BadgeContainer);
app.component("k-badge", Badge);
app.component("k-svgIcon", SvgIcon);
app.component("k-autoComplete", AutoComplete);
app.component("vue-timepicker", VueTimepicker);

//text editor
app.component("VueEditor", VueEditor);
//Multiple select
app.component("ax-multiselect", Multiselect);
//custom no result found
app.component("ax-noResultsFound", NoResultsFound);

//Font awesome
app.component("font-awesome-icon", FontAwesomeIcon);
//Axios Components
app.component("BaseLayout", BaseLayout);
app.component("Navigation", Navigation);
app.component("Loader", Loader);
app.component("MediaPlayer", MediaPlayer);
app.component("MediaPlayerWithSources", MediaPlayerWithSources);
app.component("AudioRecorder", AudioRecorder);
app.component("Version", Version);
app.component("Footer", Footer);
app.component("Logo", Logo);
app.component("ax-pagination", Pagination);
app.component("ax-popupTemplate", PopupTemplate);
app.component("ax-loginPopup", LoginPopup);

app.mount("#app");

const publicRouteNames = [
  "home",
  "login",
  "recovery",
  "requestrecovery",
  "signup",
];

router.beforeEach((to, from, next) => {
  const toName = to.name;
  const authenticated = true;
  //store.getters.isLoggedIn;
  if (
    authenticated ||
    toName === null ||
    toName === undefined ||
    publicRouteNames.indexOf(toName.toLowerCase()) >= 0
  ) {
    next();
  }
});
