<template>
  <div id="root">
    <Loader />
    <router-view :key="key" />
  </div>
</template>

<style lang="scss">
// Set the variables here.
@import "./assets/styles/app.scss";
</style>
<script lang="js">
import store from "./store/index";
import authService from "./services/auth-service";
export default {
  beforeCreate() {
    store.dispatch("initialise");
  },
  async mounted() {
    await authService.refreshToken();
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
}
</script>
