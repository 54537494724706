import AxiosStatic from "axios";

class FileService {
  constructor() {
    this.$http = AxiosStatic;
  }

  upload(blob, fileName, duration = 0) {
    const formData = new FormData();
    // hack audio stream duration into form data name, initial attempt put duration into the headers but didn't success
    formData.append(`x-${duration}`, blob, fileName);

    // Post the form, just make sure to set the 'Content-Type' header
    return this.$http.post("/api/File/Upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new FileService();
