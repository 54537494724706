import AxiosStatic from "axios";
import { getUrlName } from '../router';
import { formatErrorsAndRethrow } from "./error-handle";

class EmployerService {
  constructor() {
    this.$http = AxiosStatic;
  }
  list(count, organisationID) {
    return this.$http.get("/api/Employer/List/", { params: {count, organisationID}});
  }
  getByID(id) {
    return this.$http.get(`/api/Employer/Get/${id}`);
  }
  save(employer) {
    return this.$http.post("/api/Employer/Save", employer);
  }
  signUp(payload) {
    return this.$http.post("/api/Employer/SignUp", payload);
  }
  async delete(id) {
    return this.$http.delete(`/api/Employer/Delete/${id}`);
  }
  sendVerificationEmail(id) {
    return this.$http.get(`/api/employer/Verification/${id}`);
  }
  getEmployerByUrlName(urlName) {
    return this.$http.get(`/api/employer/GetDetailsByUrlName/${urlName}`);
  }
  getEmployerByContactUserID(userID) {
    return this.$http.get(`/api/employer/GetByContactUser/${userID}`);
  }

  async ensureEmployerFromUrlLoaded(route, store) {
    const urlName = await getUrlName(route) ?? store.getters.urlName ?? "";

    // const res = await this.getCurrentEmployerUrlName(urlName);
    // store.commit('setEmployerFromUrl', res.data);
    // store.commit('setUrlName', res.data.urlName)
    // return res.data;

    if(!urlName) return null;

    const response = await store.dispatch('loadEmployerFromUrl', urlName);
    return response.data;
  }

  getEmployerGeneralInfo(urlName) {
    return this.$http.get(`/api/employer/getEmployerGeneralInfo/${urlName}`)
    .catch(formatErrorsAndRethrow)
  }

  getCurrentEmployerUrlName(urlName = "") {
    return this.$http.get(`/api/employer/GetCurrentEmployer/${urlName}`)
    .catch(formatErrorsAndRethrow)
  }
}

export default new EmployerService();
