<template>
  <div id="loader">
    <loading
      v-if="isLoading"
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="true"
      :color="'#e12164'"
      :background-color="'#ababab'"
      :loader="'dots'"
    />
  </div>
</template>

<script>
import AxiosStatic from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "../store";
export default {
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      return store.getters.isLoading;
    },
  },
  data() {
    return {}
  },
  methods: {
    onCancel() {
      return true;
    }
  }
};

// Add a request interceptor
AxiosStatic.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    var isDSR = window.location.href.toLowerCase().includes("dsr");

    if (
      config.url == "/api/Version/Index" ||
      config.url == "/api/Disability/List" ||
      config.url == "/api/Industry/List" ||
      config.url == "/api/Language/List" ||
      config.url == "/api/Skill/List" ||
      config.url == "/api/State/List" ||
      config.url == "/api/JobApplication/CheckMediasStatus" ||
      config.url.includes("/api/JobApplication/GetJobApplication") ||
      (config.url.includes("/api/job/GetJobByID") && isDSR) ||
      config.url.includes("/api/MyDsr/getDsrByGuid") ||
      config.url.includes("/api/load")
    ) {
      return config;
    }

    store.commit("setIsLoading", true);
    // this.$forceUpdate();

    return config;
  },
  function (error) {
    // Do something with request error

    store.commit("setIsLoading", false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosStatic.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    store.commit("setIsLoading", false);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    store.commit("setIsLoading", false);
    return Promise.reject(error);
  }
);
</script>
