import AxiosStatic from "axios";

class LanguageService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list() {
    return this.$http.get("/api/Language/List");
  }
}

export default new LanguageService();
