<template>
  <div
    id="navigation"
    v-bind:class="{
      '': showNavigation,
      'd-none': !showNavigation,
    }"
  >
    <nav class="navbar fixed-top navbar-light navbar-expand-lg bg-white" style="min-height:71px">
      <div class="menu-inner container-fluid">
        <a class="navbar-brand cursor-pointer" style="min-width: 10%;">
          <template v-if="!employerFromUrl?.logoFileID || useDefaultLogo">
            <img
            class="d-none d-lg-inline-block"
            src="../assets/images/OwnWords-Logo.png"
            height="47"
            />
            <!-- @click="onClickLogo" -->
            <!-- width="95" -->
            <img
              class="d-inline-block d-lg-none"
              src="../assets/images/OwnWords-Icon.png"
              height="45"
            />
            <!-- @click="onClickLogo" -->
            <!-- width="45" -->
          </template>
          <template v-else>
            <img
            class="d-none d-lg-inline-block"
            :src="`/api/File/stream/${employerFromUrl.logoFileID}`"
            height="47"
            />
            <!-- @click="onClickLogo" -->
            <!-- width="95" -->
            <img
              class="d-inline-block d-lg-none"
              :src="`/api/File/stream/${employerFromUrl.logoFileID}`"
              width="45"
            />
            <!-- @click="onClickLogo" -->
            <!-- width="45" -->
          </template>
          
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="navBarActive"
        >
          <div
            class="hamburger hamburger--slider"
            v-bind:class="{
              '': !navBarActiveMobile,
              'is-active': navBarActiveMobile,
            }"
          >
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" v-for="(item, index) in menu" :key="index">
              <router-link
                v-bind:class="{
                  'nav-link': !item.Disabled,
                  'nav-link disabled': item.Disabled,
                  'nav-link selected': item.Route == currentRoute,
                }"
                :to="item.Route"
                ><div>
                  {{ item.Name }}
                </div>
                <div v-if="item.Disabled" class="coming-soon">Coming soon</div>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- hide on small screen -->
        <div class="button-wrapper d-none d-sm-block">
          <k-button
            href=""
            class="btn btn-lg-sm btn-outline-primary me-2"
            @click="ShowEmployerSignUpPage()"
            v-if="!isUserLogIn && !isAdminLogIn"
          >
            Employer sign up 
          </k-button>
          <k-button
            href=""
            class="btn btn-lg-sm btn-primary me-2"
            @click="ShowSignUpPage()"
            v-if="!isUserLogIn && !isAdminLogIn"
          >
            {{signUpLabel}}
          </k-button>
          <k-button
            href=""
            class="btn btn-lg-sm btn-secondary"
            @click="ShowLogInPage()"
            v-if="!isUserLogIn && !isAdminLogIn"
          >
            Log in
          </k-button>
          <k-button
            href=""
            class="btn btn-lg-sm btn-outline-primary me-2"
            v-if="isUserLogIn"
            @click="toProfilePage()"
          >
            Profile
          </k-button>
          <k-button
            href=""
            class="btn btn-lg-sm btn-outline-primary me-2"
            v-if="showBackToAdminBtn"
            @click="toAdminPage()"
          >
            Admin
          </k-button>
          <k-button
            href=""
            class="btn btn-lg-sm btn-secondary"
            @click="logOut()"
            v-if="isUserLogIn || isAdminLogIn"
          >
            Log Out
          </k-button>
        </div>
      </div>
    </nav>
    <!-- && isAdminLogIn -->
    <div v-if="breadcrumbs">
      <div class="breadcrumbs" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li
            @click="clickBreadCrumb(item)"
            class="breadcrumb-item"
            v-bind:class="{
              active: breadcrumbs.length == index + 1,
            }"
            aria-current="page"
            v-for="(item, index) in breadcrumbs"
            :key="index"
          >
            {{ item.name }}
          </li>
          <!-- <li class="breadcrumb-item active" aria-current="page">Home</li> -->
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import AuthService from "../services/auth-service";
import CandidateService from "../services/candidate-service";
import EmployerService from "../services/employer-service";
import { useToast } from "vue-toastification";
import { getUrlName } from '../router';
import {EmployerType} from '../enums/EmployerType';
import { UserGroup } from '../enums/UserGroup';

const toast = useToast();

const dsrMenuItems = ["Positions", "Generic DSR"];

export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    }
  },
  components: {},
  data: function () {
    return {
      adminMenu: [

        {
          Name: "Employers",
          Disabled: false,
          Route: "/employers",
        },
        {
          Name: "Organisations",
          Disabled: false,
          Route: "/organisations",
        },
        {
          Name: "Settings",
          Disabled: false,
          Route: "/settings",
        },
        {
          Name: "Charge Items",
          Disabled: false,
          Route: "/charge-items",
        },
      ],
      showNavigation: true,
      isAdmin: false,
      navBarActiveMobile: false,
      storeUrl: "",
      windowWidth: 0,
    };
  },
  beforeCreate() {
    const urlName = this.$route.params?.name ?? "null"
    if (urlName && urlName !== "null" && !urlName !== "undefined") {
      store.commit('setUrlName', this.$route.params.name);
    }
    store.dispatch("loadUrlName");
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    await this.getPublicNavItems();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  watch: {
    storeUrl: function() {
      return store.getters.urlName;
    }
  },
  computed: {
    showBreadcrumbs: function () {
      return this.breadcrumbs != null && this.isAdminLogIn;
    },
    currentRoute: function () {
      let cr = this.$router.currentRoute.value.path;
      if (this.$router.currentRoute.value.meta.navPath) {
        cr = this.$router.currentRoute.value.meta.navPath;
      }

      return cr;
    },
    hideSignupButtons() {
      // when windowWidth is less than 576px
      return this.windowWidth < 576;
    },
    menu: function () {
      if (store.getters.userGroup == "Candidate") {
        return this.candidateMenu;
      } else if (store.getters.userGroup == "Admin") {
        if (store.getters.adminEmployerID > 0) {
          // move job board from employer under organisation
          if (store.getters.employerFromUrl?.organisationID) {
            return this.employerMenu.filter(x => x.Name !== "Job Board");
          }
          return this.employerMenu.filter(x => x.Name !== "Candidate Search");
        } else if (store.getters.adminOrganisationID > 0) {
          // conditionally remove DSR menu items
          if (!store.getters.employerFromUrl?.enableDsr) {
            return this.organisationMenu.filter(x => !dsrMenuItems.includes(x.Name));
          }
          return this.organisationMenu;
        } else {
          return this.adminMenu;
        }
      } else if (store.getters.userGroup == "Employer") {
        // move job board from employer under organisation
        if (store.getters.employerFromUrl?.organisationID) {
          return this.employerMenu.filter(x => x.Name !== "Job Board");
        }
        return this.employerMenu.filter(x => x.Name !== "Candidate Search");
      } else if (store.getters.userGroup == "Organisation") {
        // conditionally remove DSR menu items
        if (!store.getters.employerFromUrl?.enableDsr) {
          return this.organisationMenu.filter(x => !dsrMenuItems.includes(x.Name));
        }
        return this.organisationMenu;
      } else {
        if (this.hideSignupButtons) {
          return [...this.publicMenu, ...this.signUpLoginMenu];
        }
        else {

          return this.publicMenu;
        }
      }
    },
    useDefaultLogo() {
      const s = store.getters;
      return s.userGroup == UserGroup.Admin && !s.adminEmployerID && !s.adminOrganisationID;
    },
    showBackToAdminBtn() {
      return store.getters.userGroup === "Admin" && (store.getters.adminOrganisationID > 0 || store.getters.adminEmployerID > 0)
    },
    isUserLogIn: function () {
      if (store.getters.userGroup == "Candidate" || store.getters.userGroup == "Employer" || store.getters.userGroup == "Organisation") {
        return true;
      } else {
        return false;
      }
    },
    isAdminLogIn: function () {
      if (store.getters.userGroup == "Admin") {
        return true;
      } else {
        return false;
      }
    },
    getUrlName() {
      if(store.getters.userGroup != UserGroup.Admin) {
        return store.getters.userEmployerUrlName;
      }

      if (!store.getters.userGroup) {
        return store.getters.employerFromUrl.urlName;
      }

      return store.getters.urlName;
    },
    getCompanySummariesUrl() {
      if (!store.getters.employerFromUrl) return "";
      return `${store.getters.employerFromUrl.employerName}/${store.getters.employerFromUrl.employerID}`
    },
    candidateMenu() {
      if (this.$store.getters.userEmployerType == EmployerType.Employer) {
        return [
          {
            Name: "Dashboard",
            Disabled: false,
            Route: `/${this.getUrlName}/dashboard`,
          },
          {
            Name: "Job Board",
            Disabled: false,
            Route: `/${this.getUrlName}/job-board`,
          },
          {
            Name: "My Jobs",
            Disabled: false,
            Route: `/${this.getUrlName}/my-jobs`,
          }
        ];
      }
      else {
        const _menuItems = [
          {
            Name: "Dashboard",
            Disabled: false,
            Route: `/${this.getUrlName}/dashboard`,
          },
          {
            Name: "Job Board",
            Disabled: false,
            Route: `/${this.getUrlName}/job-board`,
          },
          {
            Name: "Create Resume",
            Disabled: false,
            Route: `/${this.getUrlName}/create-dsr`,
          },
          {
            Name: "Explore companies",
            Disabled: false,
            Route: `/${this.getUrlName}/companies`,
          },
          {
            Name: "My Jobs",
            Disabled: false,
            Route: `/${this.getUrlName}/my-jobs`,
          },
          {
            Name: "My Resumes",
            Disabled: false,
            Route: `/${this.getUrlName}/my-dsr`,
          }
        ];

        const dsrMenuItems = ["Create Resume", "My Resumes"];
        if (!store.getters.employerFromUrl?.enableDsr) {
          return _menuItems.filter(x => !dsrMenuItems.includes(x.Name));
        }

        return _menuItems;
      }
    },
    employerMenu: function() {
      return [
        {
          Name: "Dashboard",
          Disabled: false,
          Route: `/${this.getUrlName}/dashboard`,
        },
        {
          Name: "Job Board",
          Disabled: false,
          Route: `/${this.getUrlName}/job-board`,
        },
        {
          Name: "Our Jobs",
          Disabled: false,
          Route: `/${this.getUrlName}/job-list`
        },
        {
          Name: "Candidate Search",
          Disabled: false,
          Route: `/${this.getUrlName}/candidate-search`,
        },
        {
          Name: "Candidates",
          Disabled: false,
          Route: `/${this.getUrlName}/candidates`,
        },
        {
          Name: "Users",
          Disabled: false,
          Route: `/${this.getUrlName}/users`,
        },
      ]
    },
    organisationMenu: function() {
      return [
        {
          Name: "Dashboard",
          Disabled: false,
          Route: `/${this.getUrlName}/dashboard`,
        },
        {
          Name: "Job Board",
          Disabled: false,
          Route: `/${this.getUrlName}/job-board`,
        },
        {
          Name: "Positions",
          Disabled: false,
          Route: `/${this.getUrlName}/positions`,
        },
        {
          Name: "Generic DSR",
          Disabled: false,
          Route: `/${this.getUrlName}/generic-dsr`,
        },
        {
          Name: "Our Jobs",
          Disabled: false,
          Route: `/${this.getUrlName}/job-list`
        },
        {
          Name: "Candidate Search",
          Disabled: false,
          Route: `/${this.getUrlName}/candidate-search`,
        },
        {
          Name: "Candidates",
          Disabled: false,
          Route: `/${this.getUrlName}/candidates`,
        },
        {
          Name: "Employers",
          Disabled: false,
          Route: `/${this.getUrlName}/employers`,
        },
        {
          Name: "Explore companies",
          Disabled: false,
          Route: `/${this.getUrlName}/companies`,
        },
        {
          Name: "Users",
          Disabled: false,
          Route: `/${this.getUrlName}/users`,
        },
      ]
    },
    signUpLoginMenu() {
      return [
        { 
          Name: "Employer Sign Up",
          Disabled: false,
          Route: `/${this.getUrlName}/employer-sign-up`,
        },
        {
          Name: "Sign Up",
          Disabled: false,
          Route: `/${this.getUrlName}/sign-up`,
        },
        {
          Name: "Log In",
          Disabled: false,
          Route: `/${this.getUrlName}/log-in`,
        },
      ];
    },
    publicMenu() {
      const pbMenu = [
        {
          Name: "Job Board",
          Disabled: false,
          Route: `/${this.getUrlName}/job-board`,
        }];
      
      if (this.$store.getters.employerFromUrl?.type == EmployerType.Employer)
        return [];
      else {
        const organizationMenuItems = [
          ...pbMenu,
          {
            Name: "Create Resume",
            Disabled: false,
            Route: `/${this.getUrlName}/create-dsr`,
          },
          {
            Name: "Explore companies",
            Disabled: false,
            Route: `/${this.getUrlName}/companies`,
          },
        ];

        if (this.$store.getters.employerFromUrl && !this.$store.getters.employerFromUrl.enableDsr) {
          return organizationMenuItems.filter(x => x.Name !== "Create Resume");          
        }
        
        return organizationMenuItems;
      }


    },
    employerFromUrl: function() {
      return store.getters.employerFromUrl;
    },
    signUpLabel() {
      // if (store.getters.userGroup == "" || !store.getters.userGroup) return "Candidate Sign Up";
      return "Sign Up";
    }
  },
  emits: {},
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async getPublicNavItems() {
      const employer = await EmployerService.ensureEmployerFromUrlLoaded(this.$route, store);
      if(!this.isUserLogIn && !this.isAdminLogIn) {
        if( employer != null && employer.type == EmployerType.Employer) {
          this.publicMenu = this.publicMenu.filter(m => m.Route != 'create-dsr');
        }
      }
    },
    async ShowEmployerSignUpPage() {
      const urlName = await getUrlName(this.$route);
      if(urlName) {
        this.$router.push(`/${urlName}/employer-sign-up`);
      } else {
        this.$router.push("/employer-sign-up");
      }
    },
    async ShowSignUpPage() {
      const urlName = await getUrlName(this.$route);
      if(urlName) {
        this.$router.push(`/${urlName}/sign-up`);
      } else {
        this.$router.push("/sign-up");
      }
    },
    async ShowLogInPage() {
      const urlName = await getUrlName(this.$route);
      if(urlName) {
        this.$router.push(`/${urlName}/login`);
      } else {
        this.$router.push("/log-in");
      }
    },
    logOut() {
      toast.clear();
      const urlName = this.$store.getters.urlName;
      AuthService.logout()
        .then(() => {
          if (urlName) {
            this.$router.push(`/${urlName}/login`)
          }
          else { 
            this.$router.push("/");
          }
          toast.success("Log out");
        })
        .catch(this.showAlert);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(error) {
      this.logOutError = error;
      this.dismissCountDown = this.dismissSecs;
      if (error.message !== "" || error.message !== null) {
        toast.error(error.message);
      } else {
        toast.error("Log Out Failed. Please try again.");
      }
    },
    onClickLogo() {
      const urlName = this.$store.getters.urlName;
      if (urlName) {
        this.$router.push(`/${urlName}/dashboard`);
      }
      else {
        this.$router.push("/");
      }
    },
    async toProfilePage() {
      if(store.getters.userGroup == "Employer" || store.getters.userGroup == "Organisation") {
        this.$router.push(`/${this.getUrlName}/user-details/${store.getters.userID}`);
      } else {
        let email = store.getters.email;
        const response = await CandidateService.getCandidateByEmail(email);
        const candidate = response.data.candidate;
        this.$router.push(`/${this.getUrlName}/candidate-details/${candidate.candidateID}`);
      }
    },
    clickBreadCrumb(item) {
      if (item.route == "previous") {
        // TODO goback last step is not best option
        this.$router.go(-1);
      } else if (item.route != "") {
        this.$router.push(item.route);
      }
    },
    navBarActive() {
      this.navBarActiveMobile = !this.navBarActiveMobile;
    },
    toAdminPage() {
      this.$store.commit("setAdminPage");
      this.$router.push('/employers')
    }
  },
};
</script>
