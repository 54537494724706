import AxiosStatic from "axios";
class VersionService {
  constructor() {
    this.$http = AxiosStatic;
  }
  getVersion() {
    return this.$http.get("/api/Version/Index");
  }
}

export default new VersionService();
