import AxiosStatic from "axios";
import store from "../store";
class AuthService {
  constructor() {
    this.$http = AxiosStatic;
  }
  login(user) {
    return this.$http
      .post("/api/account/login", user)
      .then((response) => {
        if (response.data.user) {
          store.dispatch("updateLogin", response.data);
        } else {
          throw new Error("An unexpected error has ocurred");
        }
      })
      .catch(this.formatErrorsAndRethrow);
  }
  async refreshToken() {
    const refreshToken = store.getters.jwtRefreshToken;
    const userID = store.getters.userID;
    if (refreshToken && userID) {
      try {
        // Only use cookie refresh if we've previously authenticated this session (tab scoped)
        let hasAuthenticatedSession = sessionStorage.getItem("sessionAuthenticated") === "true";
        let rememberMe = localStorage.getItem("remember_me") === "true";
        let useLocalStorage = rememberMe && !hasAuthenticatedSession;

        let response = await this.$http
          .post("/api/account/refreshToken", {
            refreshToken: refreshToken,
            userId: userID,
            useCookies: useLocalStorage
          });
        if (response.data) {
          store.dispatch("updateLogin", response.data);
        }

        return response;
      }
      catch (err) {
        this.formatErrorsAndRethrow(err);
      }
    }
    return {
      data: {
        jwt: {
          authenticated: false
          }
        }
    };
  }
  logout() {
    let result = this.$http.post("/api/account/LogOff")
    .catch(this.formatErrorsAndRethrow);
    result.then(function () {
      store.dispatch("clearLogin");
    });

    return result;
  }
  signup(user) {
    return this.$http
      .post("/api/account/signup", user)
      .catch(this.formatErrorsAndRethrow);
  }
  signupEmployer(employer) {
    return this.$http
      .post("/api/account/employer-signup", employer)
      .catch(this.formatErrorsAndRethrow);
  }
  requestRecovery(email) {
    return this.$http
      .post("/api/account/RequestRecovery", {
        email: email,
      })
      .catch(this.formatErrorsAndRethrow);
  }
  sendVerificationEmail(email) {
    return this.$http
      .post("/api/account/SendVerificationEmail", {
        email: email,
      })
      .catch(this.formatErrorsAndRethrow);
  }
  resetPassword(user) {
    return this.$http
      .post("/api/account/ResetPassword", user)
      .catch(this.formatErrorsAndRethrow);
  }
  formatErrorsAndRethrow(error) {
    if (error.response) {
      let err = error.response.data;
      if (err.errors) {
        err = [
          ...Object.keys(error.response.data.errors).map(
            (key) => error.response.data.errors[key]
          ),
        ];
        err = err.reduce((flat, list) => flat.concat(list));
        // console.log(err);
        err = err.reduce((val, arr) => `${val} \r\n${arr}`, "");
      }
      throw new Error(err);
    } else {
      throw new Error("An unexpected error has ocurred");
    }
  }
  getUserByGuid(guid) {
    return this.$http.get("/api/account/GetUser/" + guid);
  }

  getUser() {
    return this.$http.get("/api/account/getUser");
  }
}

export default new AuthService();
