import AxiosStatic from "axios";
import { formatErrorsAndRethrow } from "./error-handle";

class StateService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list(urlName = null) {
    return this.$http.get("/api/State/List/" + urlName ?? "")
    .catch(formatErrorsAndRethrow);
  }

}

export default new StateService();
