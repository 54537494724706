import { createRouter, createWebHistory } from "vue-router";
import EmployerService from "../services/employer-service";
import { redirectUnauthorized } from "./redirect";


const allGroup = ["Admin", "Candidate", "Employer", "Organisation", ""];
const dsrGroup = ["Admin", "Candidate", "Organisation"]
// const employerOganisationGroup = ["Employer", "Organisation"];
const adminGroup = ["Admin", "Employer", "Organisation"]
const adminOnlyGroup = ["Admin"];
const candidateGroup = ["Candidate"];
const organisationGroup = ["Admin", "Organisation"];

const routes = [
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    groups: allGroup,
    path: "/",
    redirect: "/log-in",
  },
  {
    groups: allGroup,
    path: "/log-in",
    name: "LogIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/login",
    name: "EmployerLogIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/job-board",
    name: "JobBoard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobBoard.vue"),
  },
  {
    groups: adminGroup,
    path: "/:name/edit-job/:id",
    name: "EditJob",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddNewJobPage.vue"),
  },
  {
    groups: adminGroup,
    path: "/candidates",
    name: "Candidates",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Candidates.vue"),
  },
  {
    groups: adminGroup,
    path: "/:name/candidates",
    name: "CandidatesByEmployer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Candidates.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/candidate-details/:id",
    name: "CandidateDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CandidateDetailsPage.vue"
      ),
  },
  {
    groups: adminGroup,
    path: "/:name/user-details/:id",
    name: "UserDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserDetailsPage.vue"
      ),
  },
  {
    groups: adminGroup,
    path: "/job-list",
    name: "JobList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobList.vue"),
  },
  {
    groups: adminGroup,
    path: "/:name/job-list",
    name: "JobList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobList.vue"),
  },
  {
    groups: adminGroup,
    path: "/:name/add-new-job",
    name: "AddNewJob",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddNewJobPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/job-details/:id/:view?",
    query: {
      includeClosed: Boolean,
    },
    name: "JobDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobDetails.vue"),
    props: true,
  },
  {
    groups: organisationGroup,
    path: "/job-details/:id/:view?",
    query: {
      includeClosed: Boolean,
    },
    name: "JobDetailsWithNoURLName",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobDetails.vue"),
    props: true,
  },
  {
    groups: adminOnlyGroup,
    path: "/employers",
    name: "Employers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Employers.vue"),
  },
  {
    groups: organisationGroup,
    path: "/:name/employers",
    name: "OrganisationEmployers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Employers.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/organisations",
    name: "Organisations",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Employers.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/add-new-employer",
    name: "AddNewEmployer",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EmployerDetailsPage.vue"
      ),
  },
  {
    groups: adminOnlyGroup,
    path: "/add-new-organisation",
    name: "AddNewOrganisation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EmployerDetailsPage.vue"
      ),
  },
  {
    groups: organisationGroup,
    path: "/:name/add-new-employer",
    name: "AddNewEmployerToOrganisation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EmployerDetailsPage.vue"
      ),
  },
  {
    groups: adminGroup,
    path: "/employer-details/:id",
    name: "EmployerDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EmployerDetailsPage.vue"
      ),
  },
  {
    groups: adminOnlyGroup,
    path: "/organisation-details/:id",
    name: "OrganisationDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EmployerDetailsPage.vue"
      ),
  },
  {
    groups: allGroup,
    path: "/sign-up",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignUpPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/employer-sign-up",
    name: "EmployerSignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmployerSignUpPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/sign-up",
    name: "SignUpByEmployer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignUpPage.vue"),
  },
  {
    groups: allGroup,
    path: "/invalid-link",
    name: "InvalidLink",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InvalidLinkPage.vue"),
  },
  {
    groups: allGroup,
    path: "/request-reset-password",
    name: "RequestResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/RequestResetPasswordPage.vue"
      ),
  },
  {
    groups: allGroup,
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPasswordPage.vue"),
  },
  {
    groups: allGroup,
    path: "/verification",
    name: "Verification",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPasswordPage.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/test",
    name: "TestPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestPage.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/dsr/:id/:type?",
    query: {
      review: Boolean,
    },
    name: "DSR",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DSRPage.vue"),
  },
  {
    groups: adminGroup,
    path: "/previewCandidateApplication/:id",
    name: "PreviewCandidateApplication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PreviewCandidateApplication.vue"
      ),
  },
  {
    groups: adminGroup,
    path: "/jobApplicationCandidate/:id",
    name: "JobApplicationCandidate",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/JobApplicationCandidate.vue"
      ),
  },
  {
    groups: adminOnlyGroup,
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/charge-items",
    name: "Charge Items",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChargeItems.vue"),
  },
  // create-dsr needs to be accessible by anounymous user as per ticket 102222
  {
    groups: allGroup,
    path: "/:name/create-dsr",
    name: "Organisition CreateDSR",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateDSR.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/create-dsr-details/:id",
    name: "CreateDSRDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateDSRDetails.vue"),
  },
  {
    groups: ["Organisation", "Admin"],
    path: "/:name/position-details/:id",
    name: "PositionDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateDSRDetails.vue"),
  },
  {
    groups: candidateGroup,
    path: "/:name/my-jobs",
    name: "MyJob",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyJobPage.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
  },
  {
    groups: candidateGroup,
    path: "/:name/my-dsr",
    name: "MyDsr",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyDsr.vue"),
  },
  {
    groups: dsrGroup,
    path: "/my-dsr-details/:id?",
    name: "MyDsrDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyDsrDetails.vue"),
  },
  {
    groups: dsrGroup,
    path: "/:name/generic/my-dsr-details",
    name: "GenericDsrDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyDsrDetails.vue"),
  },
  {
    groups: allGroup,
    path: "/dsr-public/:id",
    name: "myDsrPublic",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyDsrPublic.vue"),
  },
  {
    groups: adminOnlyGroup,
    path: "/load-test",
    name: "Load Test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoadTest.vue"),
  },
  {
    groups: allGroup,
    path: "/organisationDetails/:id",
    name: "Organisation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/OrganisationDetails.vue"
      ),
  },
  {
    groups: allGroup,
    path: "/:name/job-board",
    name: "Organisation Job Board",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JobBoard.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/users",
    name: "Organisation Users",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Candidates.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DashBoard.vue"),
  },
  {
    groups: allGroup,
    path: "/dashboard",
    name: "CandidateDashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DashBoard.vue"),
  },
  {
    groups:["Admin", "Organisation"], 
    path: "/:name/positions",
    name: "Positions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Position.vue"),
  },
  {
    groups:["Admin", "Organisation"], 
    path: "/:name/generic-dsr",
    name: "Generic Dsr",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GenericDsrList.vue"),
  },
  {
    groups: allGroup, 
    path: "/:name/products/:id?",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Products.vue"),
  },
  {
    groups: allGroup, 
    path: "/:name/after-payment",
    name: "AfterPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AfterPayment.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/companies",
    name: "Companies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CompaniesView.vue"),
  },
  {
    groups: allGroup,
    path: "/:name/companies/:companyName/:ID",
    name: "CompanyDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CompanyDetailsPage.vue"),
  },
  {
    groups: adminGroup,
    path: "/:name/candidate-search",
    name: "CandidateSearch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CandidateSearch.vue"),
  }
];


const routeRedirectToLogin = ["PreviewCandidateApplication"];


export async function getUrlName(route) {
  const store = await import('@/store');
  const invalidUrlName = ["undefined", "null"];
  if (!route.name) {
    if (!invalidUrlName.includes(store.default.getters.urlName)) {
      return store.default.getters.urlName;
    }
    else {
      const res = await EmployerService.getCurrentEmployerUrlName();
      store.default.commit("setUrlName", res.data);
      return res.data.urlName;
    }
  }

  if (invalidUrlName.includes(route.name) && !store.default?.getters?.urlName) {
    const res = await EmployerService.getCurrentEmployerUrlName();
    store.default.commit("setUrlName", res.data);
    return res.data.urlName;
  }
  return route.params.name;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to, from, next) => {

  let r = routes.find((a) => a.name === to.name);

  let group = localStorage.getItem("user_group");
  const invalidUrlName = [ "null", "undefined" ];

  const store = await import('@/store');
  
  // when route not exists, redirect to dashboard / default page
  const redirect = { redirect: false, toPath: ''};
  if (!to.name) {
    redirect.redirect = true;
    if (store.default.getters.isLoggedIn) {
      if (store.default.getters.userGroup === "Admin") {
        redirect.toPath = "/employers";
      } else {
        redirect.toPath = `/${store.default.getters.urlName}/dashboard`;
      }
    } else {
      redirect.toPath = "/log-in"
    }
  }
  
  await redirectUnauthorized(store, to, from, next, redirect);

  if (Object.prototype.hasOwnProperty.call(to.params, "name") && invalidUrlName.includes(to.params.name.toLowerCase())) {
    if (!store.default.getters.urlName || invalidUrlName.includes(store.default.getters.urlName)) {

      if (store.default.getters.userGroup === "Admin") {
        const id = store.default.getters.adminEmployerID || store.default.getters.adminOrganisationID;
        const res = await EmployerService.getByID(id);
        store.default.commit("setUrlName", res.data.urlName);
        store.default.commit('setEmployerFromUrl', res.data);
      } else {
        const res = await EmployerService.getCurrentEmployerUrlName();
        store.default.commit("setUrlName", res.data.urlName);
        store.default.commit('setEmployerFromUrl', res.data);
      }
    }

    to.params.name = store.default.getters.urlName;
    to.path = to.path.replace(/null/, to.params.name).replace(/undefined/, to.params.name);
    to.fullPath = to.fullPath.replace(/null/, to.params.name).replace(/undefined/, to.params.name);
  }
  let redirectToLogIn = routeRedirectToLogin.some((route) => {
    if (route === r.name) {
      return true;
    }
  });

  if (redirectToLogIn) {
    if (r.groups.includes(group)) {
      next();
    } else {
      if (candidateGroup.includes(group)) {
        next(from.path);
      } else {
        next("/log-in/?returnUrl=" + to.path);
      }
    }
  } else {
    if (r.groups == allGroup || r.groups.includes(group)) {
      if (redirect.redirect) {
        next(redirect.toPath);
      } else {
        next()
      }
    } else {
      next(from.path);
    }
  }
});
export default router;
