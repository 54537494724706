<template>
  <div
    v-if="isLoaded"
    class="
      row
      justify-content-center
      h-100vh-top-bar
      align-items-center
      login-wrapper
    "
    @keyup.enter="logIn()"
  >
    <div class="col-12 col-lg-4 text-center py-5">
      <Logo></Logo>
      <h1>{{type == 1 ? 'Candidate': 'Account'}} Log In</h1>
      <div class="form-floating mb-2 text-start">
        <input
          type="text"
          class="form-control form-control-white"
          v-model="email"
          placeholder="Email"
          @change="checkValidEmail"
          ref="email"
        />
        <label for="logIn-email">Email</label>
        <k-error v-if="isEmailInvalid">
          {{ emailValidationMessage }}
        </k-error>
      </div>
      <div class="form-floating mb-2 text-start">
        <input
          type="password"
          class="form-control form-control-white"
          v-model="password"
          placeholder="Email"
        />
        <label for="logIn-password">Password</label>
      </div>
      <div class="row mt-3 mb-5 remember-wrapper">
        <div class="col-12 text-start">
          <k-checkbox v-model="rememberOptionValue">Remember me</k-checkbox>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 text-left">
          <k-button class="btn btn-lg btn-secondary w-100" @click="logIn()"
            >Log In</k-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <a class="primary-link" @click="showRequestResetPassword"
            >Forgot your password?</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import AuthService from "../services/auth-service";
import EmployerService from "../services/employer-service";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const toast = useToast();
export default {
  name: "login-component",
  props: ["popup"],
  components: {},
  data() {
    return {
      isLoaded: false,
      email: null,
      password: null,
      loginError: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      year: new Date().getFullYear(),
      isEmailInvalid: false,
      emailValidationMessage: "",
      rememberOptionValue: false,
      urlName: "",
      type: 1, // employer or organisation login type 2, candidate login type 1
      employerLogoID: "",
    };
  },
  async created() {
    const route = useRoute();
    this.type = route.name.toLowerCase().includes("employer") ? 2 : 1;
    if (this.type === 2) {
      this.urlName = route.params.name;
      await this.getEmployer();
    } else {
      this.isLoaded = true;
    }
  },
  computed: {
  },
  mounted() {
    if (this.$refs.email) {
      this.$refs.email.focus();
    }
  },
  methods: {
    async getEmployer() {
      const res = await EmployerService.getEmployerByUrlName(this.urlName);
      const employer = res.data;
      this.employerLogoID = employer.logoFileID
      this.isLoaded = true;
    },
    checkValidEmail: function () {
      if (this.email == null || this.email == "") {
        this.emailValidationMessage = "Required";
        this.isEmailInvalid = true;
      } else if (!emailRegex.test(this.email)) {
        this.emailValidationMessage = "Invalid email";
        this.isEmailInvalid = true;
      } else {
        this.emailValidationMessage = "";
        this.isEmailInvalid = false;
      }
    },
    logIn() {
      store.commit("setRememberMe", this.rememberOptionValue);
      this.checkValidEmail();
      toast.clear();
      if (this.isEmailInvalid == false) {
        AuthService.login({
          email: this.email.trim(),
          password: this.password,
        })
          .then(() => {
            if (!this.popup) {
              let returnUrlPart = window.location.search.split("=");
              if (
                returnUrlPart.length !== 0 &&
                returnUrlPart[0].includes("returnUrl") &&
                store.getters.userGroup === "Admin"
              ) {
                this.$router.push(returnUrlPart[1]);
              } else if (store.getters.userGroup === "Employer" || store.getters.userGroup === "Organisation") {
                this.$router.push(`/${this.$store.getters.urlName}/dashboard`);
              } else if (store.getters.userGroup === "Candidate") {
                this.$router.push(`/${this.$store.getters.urlName}/dashboard`);
              } else if (store.getters.userGroup === "Admin") {
                store.commit("setAdminPage");
                this.$router.push("/employers");
              } else {
                this.$router.push("/");
              }
            } else {
              if (this.$route.params?.name != this.$store.getters.urlName) {
                // throw user back to correct employer dashboard
                this.$router.push(`/${this.$store.getters.urlName}/job-board`);
                this.$emit("closePopup");
              }
              else this.$emit("closePopup");
            }
            toast.success("Successful");
          })
          .catch(this.showAlert);
      }
    },
    logOut() {
      this.data = this.data || this.data;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(error) {
      this.loginError = error;
      this.dismissCountDown = this.dismissSecs;
      toast.error(`${error}`);
    },
    showRequestResetPassword() {
      this.$router.push("/request-reset-password");
    },
  },
};
</script>
