function formatTwoDigit(digit) {
  return digit < 10 ? ("0" + digit).slice(-2) : digit;
}

Date.prototype.toSaveDate = function () {
  var date = new Date(this.valueOf());
  var year = date.getFullYear();
  var month = formatTwoDigit(date.getMonth() + 1);
  var day = formatTwoDigit(date.getDate());
  return `${year}-${month}-${day}T00:00:00.000Z`;
};
