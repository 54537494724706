<template>
  <div class="audioRecorder align-items-center text-center">
    <div class="container">
      <audio ref="recorder" muted hidden></audio>
      <div class="row">
        <div class="col-12 text-center">
          <button
            ref="start"
            @click="startRecording"
            v-bind:class="{
              'd-none': this.uploadedAudioFileId,
            }"
          >
            Record
          </button>
        </div>
        <div class="col-12 text-center mb-2">
          <button
            ref="stop"
            @click="stopRecording"
            v-bind:class="{ 'd-none': this.uploadedAudioFileId }"
          >
            Stop Recording
          </button>
        </div>
        <div class="col-12 text-center">
          <div
            ref="significant"
            v-bind:class="{ 'd-none': this.uploadedAudioFileId }"
          >
            <p class="d-inline-flex align-items-center text-center m-0">
              <span class="record_icon"></span>
              <span class="ms-2 me-2">Recording</span>
              <span>{{ timerCount }}</span>
            </p>
          </div>
        </div>
        <div class="col-12 text-center">
          <div
            ref="upload"
            v-bind:class="{ 'd-none': this.uploadedAudioFileId }"
          >
            <p class="d-inline-flex align-items-center text-center">
              <span class="record_uploading"></span>
              <span class="ms-2">Uploading</span>
            </p>
          </div>
        </div>
      </div>
      <div class="align-items-center text-center">
        <audio
          ref="player"
          controls
          v-bind:class="{ 'd-none': !this.uploadedAudioFileId }"
        ></audio>
        <button
          ref="delete"
          @click="deleteRecord"
          v-bind:class="{ 'd-none': !this.uploadedAudioFileId }"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileService from "../services/file-service";
//const delay = (ms) => new Promise((res) => setTimeout(res, ms));
import ConvertService from "../services/convert-service";
export default {
  components: {},
  props: { audio: Object },
  data() {
    return {
      uploadedAudioFileId: null,
      mediaRecorder: null,
      stream: null,
      chunks: [],
      isRecording: false,
      constraints: {
        audio: true,
        video: false,
      },

      stopCountdown: null,
      timerCount: "0:00",
      time: 0,
    };
  },
  mounted() {
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   console.log("getUserMedia supported");
    // } else {
    //   console.log("getUserMedia is not supported on your browser!");
    // }

    if (this.audio.audioFileID) {
      this.uploadedAudioFileId = this.audio.audioFileID;
      this.$refs.player.src = `/api/File/stream/${this.uploadedAudioFileId}`;
    }
    this.$refs.start ? (this.$refs.start.style.display = "inline") : "";
    this.$refs.stop ? (this.$refs.stop.style.display = "none") : "";
    this.$refs.significant
      ? (this.$refs.significant.style.display = "none")
      : "";
    this.$refs.upload ? (this.$refs.upload.style.display = "none") : "";

    this.emitter.on("stopRecordAudio", () => {
      this.stopRecording();
    });
  },

  methods: {
    handleSuccess(stream) {
      this.stream = stream;
      this.stream.oninactive = () => {
      };
      this.$refs.recorder.srcObject = this.stream;
      this.mediaRecorder = new MediaRecorder(this.stream);
      // console.log(this.mediaRecorder);
      this.mediaRecorder.ondataavailable =
        this.onMediaRecorderDataAvailable.bind(this);
      this.mediaRecorder.onstop = this.onMediaRecorderStop.bind(this);
      this.$refs.recorder.play();
      this.mediaRecorder.start();
    },
    handleError(error) {
      console.log("navigator.getUserMedia error: ", error);
      this.emitter.emit("recordPermissionDenied", true);
      this.$refs.significant
        ? (this.$refs.significant.style.display = "none")
        : "";
    },
    onMediaRecorderDataAvailable(e) {
      this.chunks.push(e.data);
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    async onMediaRecorderStop() {
      const blob = new Blob(this.chunks, {
        type: "audio/mp3" /*type: "audio/ogg; codecs=opus"*/,
      });

      // eslint-disable-next-line no-unused-vars
      const resp = await FileService.upload(
        blob,
        this.uuidv4() + ".mp3",
        this.time
      );
      // eslint-disable-next-line no-debugger

      this.uploadedAudioFileId = resp.data[0];
      this.$emit("uploaded-file-id", this.uploadedAudioFileId);

      //const audioURL = window.URL.createObjectURL(blob);

      // this.$refs.player.src = audioURL;

      this.$refs.player.src = `/api/File/stream/${this.uploadedAudioFileId}`;

      this.chunks = [];
      this.stream.getAudioTracks().forEach((track) => track.stop());
      this.stream = null;
    },

    async startRecording() {
      //prompt to ask permission 
      navigator.mediaDevices.getUserMedia(this.constraints)
      .then(stream => {
          this.handleSuccess(stream)
          this.actuallyRecordRun();
      })
      .catch(error => {
          this.handleError(error)
      });
    },

    actuallyRecordRun() {
      this.emitter.emit("recordingAudio", true);
      if (this.isRecording) return;
      this.isRecording = true;
      this.$refs.start.innerHTML = "Recording...";
      this.$refs.player.src = "";
      this.startCountRecordTime();
      this.$refs.start ? (this.$refs.start.style.display = "none") : "";
      this.$refs.delete ? (this.$refs.delete.style.display = "none") : "";
      this.$refs.player ? (this.$refs.player.style.display = "none") : "";
      this.$refs.stop ? (this.$refs.stop.style.display = "inline") : "";
      this.$refs.significant
        ? (this.$refs.significant.style.display = "inline")
        : "";
      this.$refs.upload ? (this.$refs.upload.style.display = "none") : "";
    },

    stopRecording() {
      this.$refs.start ? (this.$refs.start.style.display = "none") : "";
      this.$refs.stop ? (this.$refs.stop.style.display = "none") : "";
      this.$refs.significant
        ? (this.$refs.significant.style.display = "none")
        : "";
      this.$refs.delete ? (this.$refs.delete.style.display = "inline") : "";
      this.$refs.player ? (this.$refs.player.style.display = "inline") : "";
      this.$refs.upload ? (this.$refs.upload.style.display = "inline") : "";
      this.$emit("recordingAudio", false);
      if (!this.isRecording) return;
      this.isRecording = false;
      this.$refs.start ? (this.$refs.start.innerHTML = "Record") : "";
      this.$refs.recorder.pause();
      this.mediaRecorder.stop();
      this.stopCountRecordTime();
    },

    deleteRecord() {
      this.$refs.start ? (this.$refs.start.style.display = "inline") : "";
      this.$refs.stop ? (this.$refs.stop.style.display = "none") : "";
      this.$refs.significant
        ? (this.$refs.significant.style.display = "none")
        : "";
      this.$refs.upload ? (this.$refs.upload.style.display = "none") : "";
      this.uploadedAudioFileId = null;
      this.$refs.delete ? (this.$refs.delete.style.display = "none") : "";
      this.$emit("deleteAudioRecord", true);
    },

    startCountRecordTime() {
      this.timerCount = "0:00";
      this.time = 0;
      this.stopCountdown = setInterval(() => {
        this.time += 1;
        this.timerCount = ConvertService.convertSecondToMinuteSecond(this.time);
      }, 1000);
    },
    stopCountRecordTime() {
      clearInterval(this.stopCountdown);
    },
  },
};
</script>
