import AxiosStatic from "axios";

class DisabilityService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list() {
    return this.$http.get("/api/Disability/List");
  }
}

export default new DisabilityService();
