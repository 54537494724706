<template>
  <div class="version d-flex justify-content-between">
    <div class="ms-5" style="width: 2rem">
        <Logo :use-default-logo="true" :not-clickable="true"></Logo>
    </div>
    <div>
      <a href="http://axiosdev.com.au" class="developer-link">
        Powered by Axios
      </a>

      <div class="build">Version {{ appVersion }}</div>
    </div>
  </div>
</template>
<script>
import store from "../store";

export default {
  name: "login",
  computed: {
    appVersion() {
      return store.getters.appVersion;
    },
  },
 async beforeCreate() {
    store.dispatch("loadAppVersion");
  },
};
</script>
