import AxiosStatic from "axios";

class CountryService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list() {
    return this.$http.get("/api/country/List");
  }
}

export default new CountryService();