import employerService from "../services/employer-service";

// eslint-disable-next-line no-unused-vars
export async function redirectUnauthorized (store, to, from, next, redirect) {

    if (!store.default.getters.userGroup) {

      await nonLoginUserRedirect(to, redirect, store);

    } else {

      await loginUserRedirect(store, to, redirect);  

    }

}

const loginPages = ['LogIn', 'EmployerLogIn'];

const myDsrPathName = ['Positions', 'MyDSR', 'MyDSRDetails', 'GenericDsrDetails', 'myDsrPublic']

const nonLoginPathName = ['LogIn', 'EmployerLogIn', 'SignUp', 'EmployerSignUp', 'SignUpByEmployer', 'InvalidLink', 'RequestResetPassword', 'ResetPassword', 'Verification', 'PreviewCandidateApplication', 'Organisation CreateDSR', 'CreateDSRDetails','myDsrPublic', 'Organisation Job Board']

const employerUnderOrganizationRedirectPathName = ['JobBoard', 'Organisation Job Board'];

// eslint-disable-next-line no-unused-vars
const redirectEmployerUnderOrganization = (store, to, redirect) => {
    if (isEmployerUnderOrganization(store.default.getters.employerFromUrl) 
    && employerUnderOrganizationRedirectPathName.includes(to.name)) {
      to.params.name = store.default.getters.employerFromUrl.organisationUrlName;
      // rewrite to path
      if (to.name === 'JobBoard' || to.name === 'Organisation Job Board') {
        redirect.redirect = true;
        const toPath = to.path.split('/');
        toPath[1] = store.default.getters.employerFromUrl.organisationUrlName;
        redirect.toPath = toPath.join('/');
        to.path = `/${store.default.getters.employerFromUrl.organisationUrlName}/job-board`;
        to.fullPath = `/${store.default.getters.employerFromUrl.organisationUrlName}/job-board`;
      } 
    }
  }

const isEmployerUnderOrganization = (employerFromUrl) => {
    if (!employerFromUrl) {
      return false;
    }

    const isOrganisationEmployer = employerFromUrl.type == 1 && (employerFromUrl.organisationID ?? 0) > 0; // employerFromUrl.type 1 = employer
    return isOrganisationEmployer;
}


async function loginUserRedirect(store, to, redirect) {
  if (to.name === 'myDsrPublic') {
    return;
  }

  if (!store.default.getters.employerFromUrl) {
    const employer = await employerService.getCurrentEmployerUrlName("");
    store.default.commit('setEmployerFromUrl', employer.data);
  }
  const { userEmployerUrlName, urlName, employerFromUrl } = store.default.getters;
  const userIdentityUrl = userEmployerUrlName || urlName || employerFromUrl.organisationUrlName || employerFromUrl?.urlName;

  if (myDsrPathName.includes(to.name) && (!employerFromUrl || employerFromUrl.enableDsr === false)) {
    redirect.redirect = true;
    // to dashboard
    redirect.toPath = `/${userIdentityUrl}/dashboard`;
  }

  else if (to.params?.name && userIdentityUrl && !nonLoginPathName.includes(to.name)) {
    const isUnauthorized = (to.params?.name.toLowerCase() ?? "") !== userIdentityUrl.toLowerCase();

    if (isUnauthorized) {

      redirect.redirect = true;
      const toPath = to.path.split('/');
      toPath[1] = userIdentityUrl;
      redirect.toPath = toPath.join('/');
    }
  }
}

async function nonLoginUserRedirect(to, redirect, store) {
  if (to.name === 'myDsrPublic') {
    return;
  }

  if (loginPages.includes(to.name) && to.fullPath.toLowerCase() !== '/log-in') {
    redirect.redirect = true;
    redirect.toPath = `/log-in`;
  } else {
    if (!store.default.getters.employerFromUrl) {
      const employer = to.params?.name
        ? await employerService.getEmployerByUrlName(to.params.name)
        : await employerService.getCurrentEmployerUrlName("");
      store.default.commit('setEmployerFromUrl', employer.data);
    }

    const { userEmployerUrlName, urlName, employerFromUrl } = store.default.getters;
    const userIdentityUrl = userEmployerUrlName || employerFromUrl.organisationUrlName || employerFromUrl?.urlName || urlName;

    if (to.params?.name && userIdentityUrl && !nonLoginPathName.includes(to.name)) {
      const isUnauthorized = (to.params?.name.toLowerCase() ?? "") !== userIdentityUrl.toLowerCase();

      if (isUnauthorized) {

        redirect.redirect = true;
        const toPath = to.path.split('/');
        toPath[1] = userIdentityUrl;
        redirect.toPath = toPath.join('/');
      }
    }
  }
}

