exports.UserGroup = {
    Admin: "Admin",
    Candidate: "Candidate",
    Organisation: "Organisation",
    Employer: "Employer",
    Anonymous: ""
}

exports.SuperAdmin = ["Admin"];
exports.AdminGroup = ["Admin", "Employer", "Organisation"];
exports.NonAdminGroup = ["Candidate", "Employer", "Organisation"];