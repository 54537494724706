import AxiosStatic from "axios";

class IndustryService {
  constructor() {
    this.$http = AxiosStatic;
  }

  list(withViewModel = false) {
    const query = {
      viewModel: withViewModel
    }
    const queryStr = new URLSearchParams(query).toString();
    return this.$http.get(`/api/Industry/List?${queryStr}`);
  }

  listWithDsrCount(urlName, viewModel = false, countDsr = false) {
    const queryStr = new URLSearchParams({urlName, viewModel, countDsr}).toString();
    return this.$http.get(`/api/Industry/ListWithDsrCount?${queryStr}`);
  }

  listPositions() {
    return this.$http.get('/api/Industry/ListPositions');
  }

  save(industries) {
    return this.$http.post("/api/Industry/save", industries);
  }
}

export default new IndustryService();
