import AxiosStatic from "axios";

class CandidateService {
  constructor() {
    this.$http = AxiosStatic;
  }
  getCandidates(id, employerUrlName) {
    const params = {};
    if(employerUrlName) {
      params.employerUrlName = employerUrlName;
    }
    return this.$http.get(`/api/candidate/${id}`, {params: params});
  }
  getCandidateByEmail(email) {
    return this.$http.get(`/api/Candidate/get/${email}`);
  }

  saveCandidate(candidate) {
    return this.$http.post("/api/Candidate/UpdateCandidate", candidate);
  }

  async deleteCandidate(id) {
    return this.$http.delete(`/api/candidate/DeleteCandidate/${id}`);
  }

  getCandidateDashboardGeneralInfo(urlName) {
    return this.$http.get(`/api/candidate/GetCandidateDashboardInfo/${urlName}`);
  }
}

export default new CandidateService();
