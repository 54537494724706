<template>
  <div class="base-layout">
    <Navigation :breadcrumbs="breadcrumbs"></Navigation>
    <slot />
    <Footer v-if="footer"></Footer>
  </div>
</template>
<script>
export default {
  name: "BaseLayout",
  props: {
    // Number with a default value
    breadcrumbs: {
      type: Array,
      default: null,
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
