import { createStore } from "vuex";
import VersionService from "../services/version-service";
import StateService from "../services/state-service";
import IndustryService from "../services/industry-service";
import SkillService from "../services/skill-service";
import LanguageService from "../services/language-service";
import DisabilityService from "../services/disability-service";
import CountryService from "../services/country-service";
import EmployerService from "../services/employer-service";
import AuthService from "../services/auth-service";

export default createStore({
  state: {
    email: "",
    userGroup: "",
    isLoading: false,
    appVersion: "",
    countries: [],
    states: [],
    industries: [],
    skills: [],
    languages: [],
    disabilities: [],
    jwtToken: null,
    jwtRefresh: null,
    userID: null,
    jwtExpiry: null,
    rememberMe: false,
    urlName: "",
    adminEmployerID: 0,
    adminOrganisationID: 0,
    employerFromUrl: null,
    employerFromUrlPromise: null,
    userEmployerID: null,
    userEmployerUrlName: null,
    userEmployerType: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isLoggedIn(state) {
      return state.email !== "";
    },
    email(state) {
      return state.email;
    },
    userGroup(state) {
      return state.userGroup;
    },
    rememberMe(state) {
      return state.rememberMe;
    },
    appVersion(state) {
      return state.appVersion;
    },
    states(state) {
      return state.states;
    },
    industries(state) {
      return state.industries;
    },
    skills(state) {
      return state.skills;
    },
    languages(state) {
      return state.languages;
    },
    disabilities(state) {
      return state.disabilities;
    },
    userID(state) {
      return state.userID;
    },
    jwtToken(state) {
      return state.jwtToken;
    },
    jwtRefreshToken(state) {
      return state.jwtRefresh;
    },
    jwtExpiry(state) {
      return state.jwtExpiry;
    },
    countries(state) {
      return state.countries;
    },
    urlName(state) {
      return state.urlName;
    },
    adminEmployerID(state) {
      return state.adminEmployerID;
    },
    adminOrganisationID(state) {
      return state.adminOrganisationID;
    },
    employerFromUrl(state) {
      return state.employerFromUrl;
    },
    employerFromUrlPromise(state) {
      return state.employerFromUrlPromise;
    },
    userEmployerID(state) {
      return state.userEmployerID;
    },
    userEmployerUrlName(state) {
      return state.userEmployerUrlName;
    },
    userEmployerType(state) {
      return state.userEmployerType;
    }
  },
  mutations: {
    setIsLoading(state, val) {
      state.isLoading = val;
    },

    setRememberMe(state, val) {
      state.rememberMe = val;
      localStorage.setItem("remember_me", val);
    },

    setUserEmail(state, val) {
      if (val === null || val === undefined) {
        state.email = "";
        localStorage.removeItem("email");
      } else {
        state.email = val;
        localStorage.setItem("email", val);
      }
    },

    setUserID(state, val) {
      if (val === null || val === undefined || Number.isNaN(val)) {
        state.userID = null;
        localStorage.removeItem("userid");
      } else {
        state.userID = val;
        localStorage.setItem("userid", val.toString());
      }
    },
    setJwtRefreshToken(state, val) {
      if (val === null) {
        state.jwtRefresh = null;
        localStorage.removeItem("jwt_refreshToken");
      } else {
        state.jwtRefresh = val;
        localStorage.setItem("jwt_refreshToken", val);
      }
    },

    setUserGroup(state, val) {
      if (val === null || val === undefined) {
        state.userGroup = "";
        localStorage.removeItem("user_group");
      } else {
        state.userGroup = val;
        localStorage.setItem("user_group", val);
      }
    },
    setJwtToken(state, val) {
      if (val === null) {
        state.jwtToken = null;
        localStorage.removeItem("jwt_token");
      } else {
        state.jwtToken = val;
        localStorage.setItem("jwt_token", val);
      }
    },
    setJwtExpiry(state, val) {
      if (val === null) {
        state.jwtExpiry = null;
        localStorage.removeItem("jwt_exp");
      } else {
        state.jwtExpiry = val;
        localStorage.setItem("jwt_exp", val);
      }
    },

    /*transitory variables that are retained in memory only*/
    setAppVersion(state, val) {
      state.appVersion = val;
    },
    setStates(state, val) {
      state.states = val;
    },
    setIndustries(state, val) {
      state.industries = val;
    },
    setSkills(state, val) {
      state.skills = val;
    },
    setLanguages(state, val) {
      state.languages = val;
    },
    setDisabilities(state, val) {
      state.disabilities = val;
    },
    setCountries(state, val) {
      state.countries = val;
    },
    setUrlName(state, val) {
      state.urlName = val;
      if (val == null) {
        localStorage.removeItem("url_name");
      } else{
        localStorage.setItem("url_name", val);
      }
    },
    setAdminEmployerID(state, val) {
      if (val === null) {
        state.adminEmployerID = 0;
        localStorage.removeItem("emp_id");
      } else {
        localStorage.setItem("emp_id", val);
        state.adminEmployerID = val;
        state.adminOrganisationID = 0;
      }
    },
    setAdminOrganisationID(state, val) {
      if (val === null) {
        state.adminOrganisationID = 0;
        localStorage.removeItem("org_id");
      } else {
        localStorage.setItem("org_id", val);
        state.adminOrganisationID = val;
        state.adminEmployerID = 0;
      }
    },
    setAdminPage(state) {
      state.adminEmployerID = 0;
      state.adminOrganisationID = 0;
      localStorage.removeItem("org_id");
      localStorage.removeItem("emp_id");
    },
    setEmployerFromUrl(state, val) {
      state.employerFromUrl = val;
    },
    setEmployerFromUrlPromise(state, val) {
      state.employerFromUrlPromise = val;
    },
    setEmployerUrlName(state, val) {
      state.employerUrlName = val;
    },
    setUserEmployerID(state, val) {
      state.userEmployerID = val;
      if (val == null) {
        localStorage.removeItem("user_employer_id");
      } else{
        localStorage.setItem("user_employer_id", val);
      }
    },
    setUserEmployerUrlName(state, val) {
      state.userEmployerUrlName = val;
      if (val == null) {
        localStorage.removeItem("user_employer_url_name");
      } else{
        localStorage.setItem("user_employer_url_name", val);
      }
    },
    setUserEmployerType(state, val) {
      state.userEmployerType = val;
      if (val == null) {
        localStorage.removeItem("user_employer_type");
      } else{
        localStorage.setItem("user_employer_type", val);
      }
    }
  },
  actions: {
    initialise({ commit }) {
      var rememberMe = localStorage.getItem("remember_me") === "true";
      commit("setRememberMe", rememberMe);

      var userid = parseInt(localStorage.getItem("userid"));
      commit("setUserID", isNaN(userid) ? null : userid);

      var email = localStorage.getItem("email");
      commit("setUserEmail", email);

      var initialRefreshToken = localStorage.getItem("jwt_refreshToken");
      commit("setJwtRefreshToken", initialRefreshToken);

      var initialToken = localStorage.getItem("jwt_token");
      commit("setJwtToken", initialToken);

      var exp = localStorage.getItem("jwt_exp");
      commit("setJwtExpiry", exp);

      var userGroup = localStorage.getItem("user_group");
      commit("setUserGroup", userGroup);

      const orgid = localStorage.getItem("org_id");
      commit("setAdminOrganisationID", orgid);

      const empid = localStorage.getItem("emp_id");
      commit("setAdminEmployerID", empid);

      const urlName = localStorage.getItem("url_name");
      commit("setUrlName", urlName);

      const userEmployerID = localStorage.getItem("user_employer_id");
      commit("setUserEmployerID", userEmployerID);

      const userEmployerUrlName = localStorage.getItem("user_employer_url_name");
      commit("setUserEmployerUrlName", userEmployerUrlName);

      const userEmployerType = localStorage.getItem("user_employer_type");
      commit("setUserEmployerType", userEmployerType);
    },
    loadAppVersion({ state, commit }) {
      if (!state.appVersion) {
        VersionService.getVersion().then((response) => {
          if (response.data) {
            commit("setAppVersion", response.data.value);
          }
        });
      }
    },
    loadStates({ state, commit }) {
      StateService.list(state.urlName).then((response) => {
        commit("setStates", response.data);
      });
    },
    loadCountries({state, commit}) {
      if (!state.countries.length) {
        CountryService.list().then((response) => {
          commit("setCountries", response.data);
        })
      }
    },
    loadIndustries({ state, commit }) {
      if (state.industries.length == 0) {
        IndustryService.list().then((response) => {
          commit("setIndustries", response.data);
        });
      }
    },
    loadSkills({ state, commit }) {
      if (state.skills.length == 0) {
        SkillService.list().then((response) => {
          commit("setSkills", response.data);
        });
      }
    },
    loadLanguages({ state, commit }) {
      if (state.languages.length == 0) {
        LanguageService.list().then((response) => {
          commit("setLanguages", response.data);
        });
      }
    },
    loadDisabilities({ state, commit }) {
      if (state.disabilities.length == 0) {
        DisabilityService.list().then((response) => {
          commit("setDisabilities", response.data);
        });
      }
    },
    updateLogin({ commit }, payload) {
      commit("setJwtToken", payload.jwt ? payload.jwt.token : null);
      commit(
        "setJwtRefreshToken",
        payload.jwt ? payload.jwt.refreshToken : null
      );
      if (payload.jwt && payload.jwt.expires) {
        commit("setJwtExpiry", new Date(payload.jwt.expires));
      } else {
        commit("setJwtExpiry", null);
      }
      commit("setUserEmail", payload.user ? payload.user.username : null);
      commit("setUserID", payload.user ? payload.user.userID : null);
      commit(
        "setUserGroup",
        payload.userGroups && payload.userGroups.length > 0
          ? payload.userGroups[0]
          : null
      );
      commit("setUrlName", payload.urlName);
      commit("setUserEmployerID", payload.userEmployerID);
      commit("setUserEmployerType", payload.userEmployerType);

      sessionStorage.setItem("sessionAuthenticated", payload.jwt.authenticated);
    },
    clearLogin({ commit }) {
      commit("setJwtToken", null);
      commit("setJwtRefreshToken", null);
      commit("setJwtExpiry", null);
      commit("setUserEmail", null);
      commit("setUserID", null);
      commit("setUserGroup", null);
      commit("setRememberMe", null);
      commit("setAdminPage");
      commit("setUrlName", null);
      commit("setUserEmployerID", null);
      commit("setUserEmployerUrlName", null);
      commit("setUserEmployerType", null);
      commit("setEmployerFromUrl", null);

      sessionStorage.setItem("sessionAuthenticated", null);
    },
    loadUrlName({state, commit}) {
      if (!state.urlName || state.urlName.trim() == "" || state.urlName === "null" || state.urlName === "undefined") {
        const id = state.adminEmployerID || state.adminOrganisationID;
        if (id) {
          EmployerService.getByID(id).then(res => {
            commit("setUrlName", res.data.urlName)
          })
        } else {
          if (state.userGroup === "Candidate") {
            AuthService.getUser().then(res => {
              if (res.data.urlName) commit("setUrlName", res.data.urlName);
            })
          }
          else if (state.userID) {
            EmployerService.getEmployerByContactUserID(state.userID)
            .then(res => {
              if (res.data.urlName) commit("setUrlName", res.data.urlName);
            })
          }
        }
      }
    },
    loadEmployerFromUrl({state, commit}, urlName) {
      if(state.urlName?.toLowerCase() != urlName?.toLowerCase() || 
        !state.employerFromUrlPromise ||
        (state.employerFromUrl != null && state.employerFromUrl.urlName != urlName)) {
        commit('setUrlName', urlName);
        commit('setEmployerFromUrl', null);
        const responsePromise = EmployerService.getEmployerByUrlName(urlName).then(response => {
          commit('setEmployerFromUrl', response.data);
          return response;
        });

        commit('setEmployerFromUrlPromise', responsePromise);
      }

      return this.getters.employerFromUrlPromise;
    },

  },
});
